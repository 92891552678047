import { Button, Select, SelectItem, User } from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";

const pairs = [
  {
    key: "USD/XYZ",
    label: "USD to XYZ token",
  },
];

export const MoreHeaderComponent = (props: {
  title?: string;
  onClose?: (_: any) => void;
}) => {
  return (
    <>
      <div className="z-30 flex flex-rows justify-between pr-8 absolute top-0 border-b-2 border-slate-300 flex w-full bg-white rounded-none items-center">
        {props.onClose ? (
          <Button
            isIconOnly
            color="warning"
            aria-label="Back"
            radius="none"
            style={{ width: "58px", height: "58px" }}
            onClick={props.onClose}
          >
            <FeatherIcon icon="arrow-left" />
          </Button>
        ) : (
          <div
            className="flex-none bg-white rounded-md"
            style={{ width: "58px" }}
          >
            <img src="/autrid_borderless.png" />
          </div>
        )}

        {props.title && <h1 className="text-lg font-bold">{props.title}</h1>}
      </div>
    </>
  );
};
