import {
  Avatar,
  DateRangePicker,
  Listbox,
  ListboxItem,
  Spinner
} from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { DateTime } from "luxon";
import { ListboxWrapper } from "../../../../../components/list-box-component";
import { first } from "lodash";
import { FormImage } from "../../../../../utils";
import { useCustomerReferredUsers } from "../../../../../lookups/customer-referred-users";
import {Customer} from '@medusajs/medusa';

export const CustomerReferredUsersListComponent = (props: {
  customerReferralCode: string;
  openOtherUser?: (user: Customer)  => void
}) => {
  const {
    items: customers,
    hasMore: hasMoreCustomers,
    isLoading,
    onLoadMore: onLoadMoreCustomers,
    refresh
  } = useCustomerReferredUsers({ customerReferralCode: props.customerReferralCode });

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreCustomers,
    isEnabled: true,
    shouldUseLoader: true, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreCustomers,
  });

  return (
    <div className="flex flex-col items-start">
      {/* <div style={{ marginTop: "56px" }}></div> */}

      <div className={`grow px-2 w-full`}>
        <ListboxWrapper>
          <Listbox
            ref={scrollerRef}
            bottomContent={<div ref={_ as any}> {isLoading && <Spinner  size="sm" />}</div>}
            classNames={{
              base: "",
              list: "overflow-scroll",
            }}
            // style={{maxHeight: 'calc(100vh - 200px)'}}
            defaultSelectedKeys={["1"]}
            topContent={
              <DateRangePicker
                startContent={isLoading ? <Spinner size="sm" /> : undefined}
                label="Referred Between"
                onChange={(ev) => refresh(ev)}
                className=""
              />
            }
            items={customers}
            label="Referred Users"
            selectionMode="none"
            variant="flat"
          >
            {(item) => (
              <ListboxItem
                key={item.id}
                textValue={`${item.last_name} ${item.first_name}`}
                onClick={()=> props.openOtherUser && props.openOtherUser(item)}
              >
                <div className="flex gap-2 items-center">
                  <Avatar
                    alt={"avatar"}
                    className="flex-shrink-0"
                    size="sm"
                    src={
                      first(
                        (item.metadata?.avatar as FormImage[] | undefined) ?? []
                      )?.url
                    }
                  />
                  <div className="flex flex-col">
                    <span className="text-small">
                      {item.last_name ?? ""}&nbsp; {item.first_name ?? ""}
                    </span>
                    <span className="text-tiny text-default-400">
                      {item.email}
                    </span>
                  </div>
                </div>
              </ListboxItem>
            )}
          </Listbox>
        </ListboxWrapper>
      </div>
    </div>
  );
};
