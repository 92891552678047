import Medusa from "@medusajs/medusa-js";

export type FormImage = {
  url: string;
  name?: string;
  size?: number;
  nativeFile?: File;
};


export function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  export const LOCAL_STORAGE_LAST_VISIBLE_TRADE_PAIR = 'last_visible_trade_pair';
  export const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'admin_access_token';
  export const LOCAL_STORAGE_DEMO_MODE_KEY = 'demo_mode';
  export const LOCAL_STORAGE_PREFER_LINE_CHART_KEY = 'prefer_line_chart';

  