import React from "react";
import { BASE_URL } from "../utils/constants";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../utils";
import { Customer } from "@medusajs/medusa";
import { useMedusa } from "medusa-react";
import { debounce } from "lodash";
import { toast } from "react-toastify";

export type UseCustomerListProps = {
  /** Delay to wait before fetching more items */
  fetchDelay?: number;
};

export function useCustomerList({ fetchDelay = 0 }: UseCustomerListProps = {}) {
  const [items, setItems] = React.useState<Customer[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const limit = 10; // Number of items per page, adjust as necessary
  let q: string;

  const { client: medusaClient } = useMedusa();

  const loadCustomers = async (currentOffset: number, forceFetch = false) => {
    if(!forceFetch && (!hasMore || isLoading)) return;
    const controller = new AbortController();
    const { signal } = controller;

    try {
      setIsLoading(true);

      const {
        customers,
        offset: _offset,
        limit: _limit,
        count: _count,
      } = await medusaClient.admin.customers.list({
        limit: (()=>limit)(),
        offset: (()=>currentOffset)(),
        q: (() => q || undefined)(),
      });

      setItems((prevItems) => [...prevItems, ...customers]);
      setHasMore(
        !(_offset == 0 ? items.length >= _count : _offset + 1 >= _count)
      );
      setCount(_count);
    } catch (error: any) {
      if((error?.response as any)?.status == 401) {
        
        localStorage.clear();
        window.location.pathname = '/';
      } else
      if ((error as any).name === "AbortError") {
        console.log("Fetch aborted");
      } else if ((error as any).message) {
        console.error((error as any).message);
      } else {
        console.error("There was an error with the fetch operation:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadCustomers(offset);
  }, []);

  const onLoadMore = debounce(() => {
    if((!hasMore || isLoading)) return;
    
    const newOffset = offset + limit;
    setOffset(newOffset);
    loadCustomers(newOffset);
  }, 300);

  const fireSearch = debounce(() => {
    setOffset(0);
    setItems([]);
    loadCustomers(0, true);
  }, 300)

  return {
    items,
    hasMore,
    isLoading,
    onLoadMore,
    count,
    setQ: (_q: string) => {
      q=_q;
      onLoadMore.cancel();
      fireSearch.cancel();
      fireSearch();
    }
  };
}
