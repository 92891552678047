import { Button, Listbox, ListboxItem, Spinner, User } from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { first } from "lodash";
import { useNavigate } from "react-router-dom";
import { ListboxWrapper } from "../../../../components/list-box-component";
import { MoreHeaderComponent } from "./components/header-component";
import { useNotificationsList } from "../../../../lookups/notifications";
import { DateTime } from "luxon";
import FeatherIcon from "feather-icons-react";
import { useContext } from "react";
import { LoadingContext } from "../../../..";
import { BASE_URL } from "../../../../utils/constants";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../../../utils";
import { toast } from "react-toastify";

export const NotificationsListFragment = (props: {}) => {
  const {
    items: notifications,
    hasMore: hasMoreNotifications,
    isLoading,
    onLoadMore: onLoadMoreNotifications,
    refresh,
  } = useNotificationsList({});
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreNotifications,
    isEnabled: true,
    shouldUseLoader: true, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreNotifications,
  });

  const deleteNotification = (id: any) => {
    setLoading!(true);

    fetch(`${BASE_URL}admin/domain/notifications/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (response.status >= 400 && response.status < 600) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }
        refresh();
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <div className="flex flex-col items-start">
      {/* <div style={{ marginTop: "56px" }}></div> */}

      <div className="mt-2 mb-2">
        <MoreHeaderComponent title="Notifications" refresh={refresh} />
      </div>
      <div className={`grow px-2 w-full`}>
        <ListboxWrapper className="">
          <Listbox
            classNames={{
              base: "",
              list: " overflow-scroll",
            }}
            // style={{maxHeight: 'calc(100vh - 200px)'}}
            defaultSelectedKeys={["1"]}
            items={notifications}
            ref={scrollerRef}
            bottomContent={<div ref={_ as any}> {isLoading && <Spinner  size="sm" />}</div>}
            label="Notifications"
            topContent={isLoading ? <Spinner size="sm" /> : undefined}
            selectionMode="none"
            variant="flat"
          >
            {(item) => (
              <ListboxItem key={item.id} textValue={item.id}>
                <div className="flex flex-col items-start">
                <Button
                      isIconOnly
                      color="danger"
                      className="absolute top-0 right-0"
                      size="sm"
                      aria-label="Delete notificaiton"
                      radius="none"
                      onClick={() => deleteNotification(item.id)}
                    >
                      <FeatherIcon icon="delete" />
                    </Button>

                    <p className="text-md font-semibold">{item.title}</p>
                    
                  {item.content && (
                    <p
                      className="text-slate-600 text-sm"
                      style={{ whiteSpace: "normal" }}
                    >
                      {item.content}
                    </p>
                  )}{" "}
                  {item.image && item.image.url && <img src={item.image.url} />}
                  <h5 className="text-small tracking-tight text-default-400">
                    {DateTime.fromISO(item.created_at).toLocaleString(
                      DateTime.DATETIME_SHORT
                    )}
                  </h5>
                </div>

                <div
                  className="w-full bg-slate-200 mt-2"
                  style={{ height: 1 }}
                ></div>
              </ListboxItem>
            )}
          </Listbox>
        </ListboxWrapper>
      </div>
    </div>
  );
};
