import { Tab, Tabs } from "@nextui-org/react";
import { useState } from "react";
import { CustomerDepositListComponent } from "./components/customer-deposit-list-component";
import { CustomerWithdrawalListComponent } from "./components/customer-withdrawal-list-component";
import { MoreHeaderComponent } from "./components/header-component";
import { Deposit } from "../../../../lookups/deposits";
import { DepositDetailFragment } from "./deposit-detail-fragment";
import { Withdrawal } from "../../../../lookups/withdrawals";
import { WithdrawalDetailDialogFragment } from "./withdrawal-detail-dialog-component";

export const DepositWithdrawalsFragment = (props: { onClose?: () => void }) => {
  const [state, setState] = useState<{
    activeDeposit?: Deposit;
    activeWithdrawal?: Withdrawal;
  }>({
    activeDeposit: undefined,
    activeWithdrawal: undefined,
  });

  return (
    <>
      <div
        className={`flex flex-col items-start ${
          state.activeDeposit ? "hidden" : ""
        }`}
      >
        {/* <div style={{ marginTop: "56px" }}></div> */}

        <div className="mt-2 mb-2">
          <MoreHeaderComponent title="Deposits & Withdrawals" />
        </div>

        <Tabs
          key={"deposits"}
          size={"sm"}
          aria-label="Customer Detail Tabs"
          className="mt-6 mb-4 self-center"
        >
          <Tab key="deposits" title="Deposits" className="w-full">
            <CustomerDepositListComponent
            showCustomer
              activateDeposit={(deposit) =>
                setState({ ...state, activeDeposit: deposit })
              }
            />
          </Tab>
          <Tab key="withdrawals" title="Withdrawals" className="w-full">
            <CustomerWithdrawalListComponent
            showCustomer
              activateWithdrawal={(withdrawal) =>
                setState({ ...state, activeWithdrawal: withdrawal })
              } />
          </Tab>
        </Tabs>
      </div>

      {state.activeDeposit && (
        <DepositDetailFragment
          deposit={state.activeDeposit}
          closeModal={(_) => {
            setState({ ...state, activeDeposit: undefined });
          }}
        />
      )}

      {state.activeWithdrawal && (
        <WithdrawalDetailDialogFragment
          withdrawal={state.activeWithdrawal}
          closeModal={(_) => {
            setState({ ...state, activeWithdrawal: undefined });
          }}
        />
      )}
    </>
  );
};
