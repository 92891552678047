import { Chip, DateRangePicker, Listbox, ListboxItem, Spinner, User } from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { DateTime } from "luxon";
import { ListboxWrapper } from "../../../../../components/list-box-component";
import {
  useCustomerWithdrawalsList,
  Withdrawal,
} from "../../../../../lookups/withdrawals";
import { first } from "lodash";
import { FormImage } from "../../../../../utils";

export const CustomerWithdrawalListComponent = (props: {
  customerId?: string;
  showCustomer?: boolean;
  activateWithdrawal: (withdrawal: Withdrawal) => void;
}) => {
  const {
    items: withdrawals,
    hasMore: hasMoreWithdrawals,
    isLoading,
    onLoadMore: onLoadMoreWithdrawals,refresh
  } = useCustomerWithdrawalsList({ customerId: props.customerId });

  const statusColorMap = {
    pending: "warning",
    approved: "success",
    denied: "danger",
    cancelled: "danger",
  };

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreWithdrawals,
    isEnabled: true,
    shouldUseLoader: true, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreWithdrawals,
    
  });

  return (
    <div className="flex flex-col items-start">
      {/* <div style={{ marginTop: "56px" }}></div> */}

      <div className={`grow px-2 w-full`}>
        <ListboxWrapper>
          <Listbox
            classNames={{
              base: "",
              list: "overflow-scroll",
            }}
            // style={{maxHeight: 'calc(100vh - 200px)'}}
            defaultSelectedKeys={["1"]}
            items={withdrawals}
            topContent={
              <DateRangePicker
                startContent={isLoading ? <Spinner size="sm" /> : undefined}
                label="Range"
                onChange={(ev) => refresh(ev)}
                className=""
              />
            }
            ref={scrollerRef}
            bottomContent={<div ref={_ as any}> {isLoading && <Spinner  size="sm" />}</div>}
            label="Withdrawals"
            selectionMode="none"
            variant="flat"
          >
            {(item) => (
              <ListboxItem
                key={item.id}
                textValue={item.id}
                onClick={() => {if(item.status == 'pending')props.activateWithdrawal(item)}}
              >
                {props.showCustomer ? (
                  <User
                    name={`${item.user.last_name ?? ""} ${
                      item.user.first_name ?? ""
                    }`.trim()}
                  
                    description={(item.user as any).level}
                    avatarProps={{
                      size:'sm',
                      src: first(
                        (item.user.metadata?.avatar as
                          | FormImage[]
                          | undefined) ?? []
                      )?.url,
                    }}
                  />
                ) : (
                  <></>
                )}
                <div className="flex justify-between items-center">
                  <div className="flex flex-col gap-1 items-start justify-center">
                    <h4
                      className={`text-small font-semibold leading-none text-red-600`}
                    >
                      {"-"}
                      {new Intl.NumberFormat("en-US", {
                        currency: "NGN",
                        style: "currency",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(item.amount)}
                    </h4>
                    <h5 className="text-small tracking-tight text-default-400">
                      {DateTime.fromISO(item.created_at).toLocaleString(
                        DateTime.DATETIME_SHORT
                      )}
                    </h5>
                  </div>

                  {
                    <Chip
                      className="capitalize"
                      color={statusColorMap[item.status] as any}
                      size="sm"
                      variant="flat"
                    >
                      {item.status}
                    </Chip>
                  }
                </div>
                <div
                  className="w-full bg-slate-200 mt-2"
                  style={{ height: 1 }}
                ></div>
              </ListboxItem>
            )}
          </Listbox>
        </ListboxWrapper>
      </div>
    </div>
  );
};
