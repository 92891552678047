import { Customer } from '@medusajs/medusa';
import { Select, SelectItem, Tab, Tabs } from "@nextui-org/react";
import { CustomerDetailHeaderComponent } from "./components/customer-detail-header-component";
import { CustomerTokenListComponent } from "./components/customer-tokens-list-component";
import { CustomerReferredUsersListComponent } from './components/customer-referred-users-list-component';
import { CustomerDepositListComponent } from '../more/components/customer-deposit-list-component';
import { CustomerWithdrawalListComponent } from '../more/components/customer-withdrawal-list-component';
import { Deposit } from '../../../../lookups/deposits';
import { Withdrawal } from '../../../../lookups/withdrawals';
import { useState } from 'react';
import { DepositDetailFragment } from '../more/deposit-detail-fragment';
import { WithdrawalDetailDialogFragment } from '../more/withdrawal-detail-dialog-component';
import { CustomerOpenTradesListComponent } from '../assets/components/customer-open-trades-list-component';

export const CustomerDetailFragment = (props: {customer: Customer, onClose?: (customer?: Customer)=> void}) => {
  const [state, setState] = useState<{
    activeDeposit?: Deposit;
    activeWithdrawal?: Withdrawal;
  }>({
    activeDeposit: undefined,
    activeWithdrawal: undefined,
  });
  
  return (
    <>
    <div className={`flex flex-col items-start ${state.activeDeposit?'hidden':''}`}>
      {/* <div style={{ marginTop: "56px" }}></div> */}

      <div className="mt-2 mb-2">
        <CustomerDetailHeaderComponent customer={props.customer} onClose={props.onClose} />
      </div>


      <div className="flex justify-between w-full flex-rows px-4">
        <div className="flex flex-col ">
          

          <h4 className="text-small font-semibold leading-none text-default-600">
            Balance
          </h4>

          <p className="text-lg font-semibold">
            {new Intl.NumberFormat("en-US", {
              currency: "NGN",
              style: "currency",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format((props.customer as any).accountBalance ?? 0)}
          </p>
        </div>
        <div className="flex flex-col  items-end">
          <h4 className="text-small font-semibold leading-none text-default-600">
            Referral Balance
          </h4>

          <p className="text-lg font-semibold">
            {new Intl.NumberFormat("en-US", {
              currency: "NGN",
              style: "currency",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format((props.customer as any).referralAccountBalance ?? 0)}
          </p>
        </div>
      </div>



      <Tabs
        key={"tokens"}
        size={"sm"}
        aria-label="Customer Detail Tabs"
        className="mt-6 mb-4 self-center"
        style={{
           maxWidth: '100%'
        }}
      >
        <Tab key="tokens" title="Tokens"  className="w-full" ><CustomerTokenListComponent customerId={props.customer.id} /></Tab>
          
        <Tab key="deposits" title="Deposits" className="w-full">
            <CustomerDepositListComponent
            customerId={props.customer.id} 
              activateDeposit={(deposit) =>
                setState({ ...state, activeDeposit: deposit })
              }
            />
          </Tab>
          <Tab key="withdrawals" title="Withdrawals" className="w-full">
            <CustomerWithdrawalListComponent
            customerId={props.customer.id} 
              activateWithdrawal={(withdrawal) =>
                setState({ ...state, activeWithdrawal: withdrawal })
              } />
          </Tab>
        <Tab key="referred_users" title="Referred Users" className="w-full" ><CustomerReferredUsersListComponent
        openOtherUser={(_)=> props.onClose &&props.onClose(_)}
        customerReferralCode={(props.customer as any).referralCode} /></Tab>
        
        <Tab key="open_trades" title="Trades" className="w-full">
          <CustomerOpenTradesListComponent userId={props.customer.id}  />
        </Tab>
      </Tabs>




    </div>
    
    {state.activeDeposit && (
        <DepositDetailFragment
          deposit={state.activeDeposit}
          closeModal={(_) => {
            setState({ ...state, activeDeposit: undefined });
          }}
        />
      )}

      {state.activeWithdrawal && (
        <WithdrawalDetailDialogFragment
          withdrawal={state.activeWithdrawal}
          closeModal={(_) => {
            setState({ ...state, activeWithdrawal: undefined });
          }}
        />
      )}
    </>
  );
}