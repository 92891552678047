import {
  Button,
  Card,
  CardBody,
  CardHeader,
  User
} from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import { MoreHeaderComponent } from "./components/header-component";
import { MoreTileComponenets } from "./components/more-tile-component";

export const MoreFragment = () => {
  

  return (
    <div className="flex flex-col items-start">
      {/* <div style={{ marginTop: "56px" }}></div> */}

      <div className="mt-2 mb-2">
        <MoreHeaderComponent />
      </div>


      <div className="mt-8 w-full">
        <MoreTileComponenets />
      </div>
    </div>
  );
};
