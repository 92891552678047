import { useContext } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LoadingContext } from "..";

import "react-toastify/dist/ReactToastify.css";
import { LoadingDialog } from "../components/loading-dialog";

export default function Root() {
  const { state: navigationState } = useNavigation();

  const { loading } = useContext(LoadingContext);

  return (
    <>
      {/* <!-- Preloader --> */}

      <Outlet />
      <LoadingDialog isOpen={navigationState === "loading" ||navigationState === "submitting" || loading} />
      {/* <ToastContainer /> */}
    </>
  );
}
