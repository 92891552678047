import { Avatar, Button, Select, SelectItem } from "@nextui-org/react";
import { useState } from "react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { Asset, useAssetList } from "../../../../../lookups/assets";
import FeatherIcon from "feather-icons-react";
import { NewTokenComponentDialog } from "./new-token-component";
import { first } from "lodash";
import { Chance } from "chance";

export const AssetsHeaderComponent = (props: {
  onAssetChange?: (asset: Asset) => void;
  selectedKeys: string[]
}) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(props.selectedKeys);
  const [isAssetsDropdownOpen, setIsAssetsDropdownOpen] = useState(false);
  const [isNewAssetDialogOpen, setIsNewAssetDialogOpen] = useState(false);
  const {
    items: assets,
    hasMore: hasMoreAssets,
    isLoading,
    onLoadMore: onLoadMoreAssets,
  } = useAssetList({ fetchDelay: 1500 , key: first(props.selectedKeys??[]), onKeyFound: (asset)=>{
    props.onAssetChange!(asset);
    setSelectedKeys([asset.symbol]);
  }});

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreAssets,
    isEnabled: isAssetsDropdownOpen,
    shouldUseLoader: false, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreAssets,
  });

  return (
    <>
      <div className="z-30 absolute top-0 border-b-2 border-slate-300 flex w-full bg-white rounded-none">
        <div
          className="flex-none bg-white rounded-md"
          style={{ width: "58px" }}
        >
          <img src="/autrid_borderless.png" />
        </div>

        <Select
          isLoading={isLoading}
          items={assets}
          radius="none"
          label="Trading Pair"
          placeholder="Select Pair"
          defaultSelectedKeys={selectedKeys}
          scrollRef={scrollerRef}
          onChange={(ev) => {
            const asset = assets.find((a) => a.symbol == ev.target.value);
            props.onAssetChange && asset && props.onAssetChange(asset);
          }}
          selectionMode="single"
          className="grow"
          onOpenChange={setIsAssetsDropdownOpen}
        >
          {(item) => (
            <SelectItem
              key={item.symbol}
              className="capitalize"
              startContent={
                item.metadata &&
                item.metadata.avatar &&
                item.metadata.avatar[0] && (
                  <Avatar size="sm" src={item.metadata.avatar[0].url} />
                )
              }
              endContent={
                <span
                  className={`text-sm ${
                    item.status == "draft"
                      ? "text-orange-500"
                      : "text-green-400"
                  }`}
                >
                  {item.status}
                </span>
              }
            >
              {`${item.symbol}/NGN ${item.level.indexOf('vip')>-1?'🔥':''}`}
            </SelectItem>
          )}
        </Select>

        <Button
          isIconOnly
          color="warning"
          aria-label="New Token"
          radius="none"
          style={{ width: "75px", height: "58px" }}
          onClick={() => setIsNewAssetDialogOpen(true)}
        >
          <FeatherIcon icon="plus" />
        </Button>
      </div>

      <NewTokenComponentDialog
      key={`new_token_${(new Chance()).string({length: 3, symbols: false})}`}
        isOpen={isNewAssetDialogOpen}
        closeModal={() => {
          setIsNewAssetDialogOpen(false);
        }}
      ></NewTokenComponentDialog>
    </>
  );
};
