import React from "react";
import { BASE_URL } from "../utils/constants";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../utils";
import {Customer} from '@medusajs/medusa';
import { debounce, first } from "lodash";
import { DateValue, RangeValue } from "@nextui-org/react";

export type UseCustomerWithdrawalsListProps = {
  /** Delay to wait before fetching more items */
  customerId?: string;
};
export type Withdrawal = {
    id: string;
  amount: number;
  created_at: string;
  user: Customer;
  status: 
  'pending'|
'approved'|
'denied'|
'cancelled'
};

export function useCustomerWithdrawalsList({customerId}: UseCustomerWithdrawalsListProps) {
  const [items, setItems] = React.useState<Withdrawal[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const limit = 10; // Number of items per page, adjust as necessary

  const loadCustomerWithdrawals = async (currentOffset: number, range?:RangeValue<DateValue>, forceFetch = false) => {
    if(!forceFetch && (!hasMore || isLoading)) return;
    const controller = new AbortController();
    const { signal } = controller;

    try {
      setIsLoading(true);

      const req = await fetch(
        `${BASE_URL}admin/withdrawals?offset=${currentOffset}&limit=${limit}${customerId ? `&userId=${customerId}`:''}${range ?  `&start_date=${range.start}&end_date=${range.end}`:''}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LOCAL_STORAGE_ACCESS_TOKEN_KEY
            )}`,
          },signal
        }
      );

      if (!req.ok) {
        const { message, errors }: { message: string, errors: string[]  } = await req.json();
        throw new Error(message ?? first([errors]));
      } if(req.status == 401) {
        // logout
        localStorage.clear();
        window.location.pathname = '/';
      }

      const {
        withdrawals,
        offset: _offset,
        limit: _limit,
        count: _count,
      }: {
        withdrawals: Withdrawal[];
        offset: number;
        limit: number;
        count: number;
      } = await req.json();

      setItems((prevItems) => [...prevItems, ...withdrawals]);
      setHasMore(
        !(_offset == 0 ? items.length >= _count : _offset + 1 >= _count)
      );
    } catch (error: any) {
      if((error?.response as any)?.status == 401) {
        
        localStorage.clear();
        window.location.pathname = '/';
      }else
      if ((error as any).name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("There was an error with the fetch operation:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadCustomerWithdrawals(offset);
  }, []);

  const onLoadMore = debounce(() => {
    if((!hasMore || isLoading)) return;

    const newOffset = offset + limit;
    setOffset(newOffset);
    loadCustomerWithdrawals(newOffset);
  }, 300);

  const refresh = (range?: RangeValue<DateValue>) => {
    onLoadMore.cancel();
    setOffset(0);
    setItems([]);
    loadCustomerWithdrawals(0, range, true);
  }

  return {
    items,
    hasMore,
    isLoading,
    onLoadMore,refresh
  };
}
