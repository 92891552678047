import {
  Avatar,
  Badge,
  Input,
  Link,
  Listbox,
  ListboxItem,
  Spinner,
  User,
} from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { ListboxWrapper } from "../../../../../components/list-box-component";
import { useCustomerAssetList } from "../../../../../lookups/customer-assets";
import FeatherIcon from "feather-icons-react";
import { first } from "lodash";
import { FormImage } from "../../../../../utils";

export const CustomerTokenListComponent = (props: { customerId: string }) => {
  const {
    items: assets,
    hasMore: hasMoreAssets,
    isLoading,
    onLoadMore: onLoadMoreAssets,
    setQ
  } = useCustomerAssetList({ customerId: props.customerId });

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreAssets,
    isEnabled: true,
    shouldUseLoader: true, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreAssets,
  });

  return (
    <div className="flex flex-col items-start">
      <div className={`grow px-2 w-full`}>
        <ListboxWrapper >
          <Listbox
            ref={scrollerRef}
            bottomContent={<div ref={_ as any}> {isLoading && <Spinner  size="sm" />}</div>}
            classNames={{
              base: "",
              list: "page-listbox-2 overflow-scroll",
            }}
            // style={{maxHeight: 'calc(100vh - 200px)'}}
            defaultSelectedKeys={["1"]}
            items={assets}
            label="Assets"
            selectionMode="none"
            variant="flat"
            
            topContent={
              <Input
                label="Search Tokens"
                isClearable
                radius="md"
                className="w-full"
                onChange={(ev) => {
                  setQ(ev.target.value);
                }}
                placeholder="Type to search..."
                startContent={
                  isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <FeatherIcon icon="search" />
                  )
                }
              />
            }
          >
            {(item) => (
              <ListboxItem key={item.id} textValue={item.symbol}>
                <div className="flex flex-rows justify-between items-center">

                  <User
                    className="self-start"
                    name={`${new Intl.NumberFormat("en-US", {
                      currency: "USD",
                      style: "currency",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .format(item.balance? (Math.floor(((Number(item.balance!))+ Number.EPSILON) * 100) / 100) :  0)
                      .replace("$", "")} ${item.symbol}/NGN ${item.level.indexOf('vip')>-1?'🔥':''}`}
                    description={`≈${new Intl.NumberFormat("en-US", {
                      currency: "NGN",
                      style: "currency",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,

                    }).format((item.currentPrice ?? 0) * (item.balance ?? 0))}`}
                    
                    avatarProps={{
                      src: first(
                        (item.metadata?.avatar as FormImage[] | undefined) ?? []
                      )?.url,
                    }}
                  />
                </div>

                <div
                  className="w-full bg-slate-200 mt-2"
                  style={{ height: 1 }}
                ></div>
              </ListboxItem>
            )}
          </Listbox>
        </ListboxWrapper>
      </div>
    </div>
  );
};
