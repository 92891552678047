import {
  Card,
  CardHeader,
  Divider,
  CardBody,
  CardFooter,
  Tabs,
  Tab,
  Button,
  Select,
  SelectItem,
  Input,
  Avatar,
} from "@nextui-org/react";
import { Link } from "react-router-dom";
import { Asset } from "../../../../../lookups/assets";
import { NewTokenComponentDialog } from "./new-token-component";
import { useState } from "react";
import { Chance } from "chance";

export const AssetDetailComponent = (props: { asset?: Asset }) => {
  
  const [isUpdateAssetDialogOpen, setIsUpdateAssetDialogOpen] = useState(false);
  const [asset, setAsset] = useState<Asset|undefined>(props.asset);

  return (
    <>
      <small className="text-default-500">Circulation Information</small>

      <div
        className="w-full bg-slate-200 mt-2 mb-4"
        style={{ height: 1 }}
      ></div>

      {asset && (
        <>
          <div className="flex flex-rows items-center">
            {asset.metadata.avatar && asset.metadata.avatar[0] && (
              <Avatar size="md" src={asset.metadata.avatar[0].url} />
            )}
            <div className="ml-2 flex flex-col items-start">
              <p className="text-md font-semibold">
                {asset.symbol}/NGN {asset.level.indexOf('vip')>-1?'🔥':''}
                &nbsp; (
                <span className="font-regular">
                  {" "}
                  NGN {asset.metadata.price_min} - NGN{" "}
                  {asset.metadata.price_max}
                </span>
                )
              </p>

              <span
                className={`text-sm ${
                  asset.status == "draft"
                    ? "text-orange-500"
                    : "text-green-400"
                }`}
              >
                {asset.status}
              </span>
            </div>

            <div className="grow"></div>

            {/* put the amount in circulation here */}

            <div className="flex flex-col items-end">
              <p className="text-md font-semibold">{asset.circulationAmount ?? 0} </p>
              <p className="text-sm">units in circulation </p>
            </div>
          </div>
        </>
      )}

      <Button type="submit" className="mt-4" fullWidth={true} color="warning" 
          onClick={() => setIsUpdateAssetDialogOpen(true)}>
        Update Asset
      </Button>

      
      {asset && <NewTokenComponentDialog
      key={`update_token_${asset.symbol}_${(new Chance()).string({length: 3, symbols: false})}`}
        isOpen={isUpdateAssetDialogOpen}
        asset={asset}
        closeModal={(asset) => {
          if(asset) setAsset(asset);
          setIsUpdateAssetDialogOpen(false);
        }}
      ></NewTokenComponentDialog>}
    </>
  );
};
