import { Tabs, Tab } from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import { NavLink, Outlet } from "react-router-dom";

export default function HomePage() {
  return (
    <>
      <div className="static overflow-y-scroll h-screen2">
        <div className="pb-40 mt-12">
          <Outlet />
        </div>
      </div>
      <div className="z-30 border-t-2 border-slate-300 grid grid-cols-4 fixed bottom-0 left-0 w-full bg-white rounded-none">
        {[
          {
            label: "Assets",
            icon: <FeatherIcon icon="dollar-sign" />,
            path: "/home/assets",
          },
          {
            label: "Customers",
            icon: <FeatherIcon icon="user" />,
            path: "/home/customers",
          },
          {
            label: "Settings",
            icon: <FeatherIcon icon="settings" />,
            path: "/home/more",
          },
          {
            label: "Notifications",
            icon: <FeatherIcon icon="bell" />,
            path: "/home/notifications",
          },
        ].map((item, index) => (
          <>
            <NavLink to={item.path!} key={index}>
              {({ isActive, isPending }) => (
                <div
                  key={index}
                  // onClick={() => item.action!({ setState } as any)}
                  className={`tab flex flex-col transition-all ${
                    isActive ? "bg-warning" : ""
                  }`}
                  data-tab="tab1"
                >
                  <div className="mx-auto w-12 tab-icon transition-all h-12 shrink-0 rounded-full inline-flex items-center justify-center bg-bgray-100">
                    {item.icon}
                  </div>
                  <div>
                    <h4 className="text-center text-base font-bold text-bgray-900 overflow-hidden">
                      {item.label}
                    </h4>
                  </div>
                </div>
              )}
            </NavLink>
          </>
        ))}
      </div>
    </>
  );
}
