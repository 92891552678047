// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import FilePondPluginImageEditor from "@pqina/filepond-plugin-image-editor";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
// import FilePondPluginImageEditor from "../../scripts/filepond-plugin-image-editor"
import {
    createDefaultImageReader,
    createDefaultImageWriter,
    getEditorDefaults,
    openEditor,
    plugin_crop,
    processImage,
    setPlugins,
} from "../../../../../scripts/pintura";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Select,
    SelectItem,
} from "@nextui-org/react";
import { isNil } from "lodash";
import { toast } from "react-toastify";
import { LoadingContext } from "../../../../..";
import { Asset } from "../../../../../lookups/assets";
import {
    FormImage,
    LOCAL_STORAGE_ACCESS_TOKEN_KEY,
} from "../../../../../utils";
import { BASE_URL } from "../../../../../utils/constants";
import { prepareImages } from "../../../../../utils/images";

// Register the plugins
registerPlugin(
  /* FilePondPluginImageExifOrientation, FilePondPluginImagePreview, */
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster,
  FilePondPluginFileValidateSize
);

setPlugins(plugin_crop);

export type NewTokenFormType = {
  symbol: string;
  price_min: number;
  price_max: number;
  status: "draft" | "published";
  level: "standard" | "pro"|'vvip'|'vip';
  avatar?: FormImage[];
};

export const NewTokenComponentDialog = (props: {
  isOpen: boolean;
  asset?: Asset;
  closeModal: (asset?: Asset) => void;
}) => {
  const schema = yup
    .object({
      symbol: yup.string().required().min(3).max(4),
      price_min: yup.number().required().default(props.asset?.metadata?.price_min ?? 100).min(5),
      price_max: yup.number().required().default(props.asset?.metadata?.price_max ?? 200).min(yup.ref("price_min")),
      status: yup.string().oneOf(["draft", "published"]).default(props.asset?.status ?? "draft"),
      level: yup.string().oneOf(["standard", "pro", "vvip", "vip"]).default(props.asset?.level ?? "standard"),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<NewTokenFormType>({
    defaultValues: {
      price_min: props.asset?.metadata?.price_min ?? 100,
      price_max: props.asset?.metadata?.price_max ?? 200,
      status: props.asset?.status ?? "draft",
      avatar: props.asset?.metadata?.avatar ?? [],
      symbol: props.asset?.symbol,
      level: props.asset?.level,
    } as any,
    resolver: yupResolver(schema as any),
  });
  const { replace: replaceAssetImage, fields: avatarFields } = useFieldArray({
    control: control,
    name: "avatar",
  } as any);

  const imageEditorOptions = {
    // Maps legacy data objects to new imageState objects (optional)
    // legacyDataToImageState: legacyDataToImageState,

    // Used to create the editor (required)
    createEditor: openEditor,

    // Used for reading the image data. See JavaScript installation for details on the `imageReader` property (required)
    imageReader: [createDefaultImageReader],

    // Required when generating a preview thumbnail and/or output image
    imageWriter: [createDefaultImageWriter],

    // Used to create poster and output images, runs an invisible "headless" editor instance
    imageProcessor: processImage,

    // Pintura Image Editor options
    editorOptions: {
      // Pass the editor default configuration options
      ...getEditorDefaults(),

      // This will set a square crop aspect ratio
      // placeholder="1200 x 1600 (3:4) recommended, up to 10MB each"
      imageCropAspectRatio: 1,
    },
  };

  const { setLoading } = useContext(LoadingContext);
  const { client: medusaClient } = useMedusa();

  const createToken = async (data: NewTokenFormType) => {
    console.error(data);
    setLoading!(true);

    let preppedImages: FormImage[] = [];

    try {
      preppedImages = await prepareImages(medusaClient, data.avatar ?? []);
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the thumbnail.";

      toast.error((error as any) ?? errorMessage);
      return;
    } finally {
      setLoading!(false);
    }

    // create the deposit and dispose
    fetch(props.asset?.id ? `${BASE_URL}admin/assets/${props.asset.symbol}`:`${BASE_URL}admin/assets`, {
      method: "POST",
      body: props.asset?.id ? JSON.stringify({
        status: data.status,
        level: data.level,
        metadata: {
          price_min: data.price_min,
          price_max: data.price_max,
          avatar: preppedImages,
        },
      }) :JSON.stringify({
        symbol: data.symbol,
        status: data.status,
        level: data.level,
        metadata: {
          price_min: data.price_min,
          price_max: data.price_max,
          avatar: preppedImages,
        },
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }

        const { asset }: { asset: Asset } = await response.json();
        // close dialog

        toast.success(props.asset?.id ?  "Asset updated successfully":"Asset created successfully");

        reset();
        props.closeModal(asset);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <>
      <Modal
        size={"sm"}
        isOpen={props.isOpen}
        onClose={() => {props.closeModal(); reset();}}
      >
              <form onSubmit={handleSubmit(createToken)}>
        <ModalContent>
          <>
            <ModalHeader className="flex flex-col gap-1">
              {props.asset ? `Update Asset ${props.asset.symbol}`:'Create New Asset'}
            </ModalHeader>
            <ModalBody>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="text-center m-auto w-32 h-32 relative mb-4">
                    {useMemo(
                      () => (
                        <FilePond
                          filePosterMaxHeight={200}
                          maxFileSize={"3072KB"}
                          stylePanelLayout={"compact circle"}
                          styleLoadIndicatorPosition="center bottom"
                          styleProgressIndicatorPosition="right bottom"
                          styleButtonRemoveItemPosition="left bottom"
                          styleButtonProcessItemPosition="right bottom"
                          acceptedFileTypes={[
                            "image/gif",
                            "image/jpeg",
                            "image/png",
                            "image/webp",
                          ]}
                          server={{
                            load: (
                              source,
                              load,
                              error,
                              progress,
                              abort,
                              headers
                            ) => {
                              var myRequest = new Request(source);
                              fetch(myRequest)
                                .then(function (response) {
                                  response.blob().then(function (myBlob) {
                                    load(myBlob);
                                  });
                                })
                                .catch(error);
                            },
                          }}
                          files={avatarFields.map((field) => ({
                            options: {
                              type: "local",
                            },
                            source: (field as any).url,
                          }))}
                          // imageEditEditor={createEditor}
                          imageEditor={imageEditorOptions}
                          // imageEditorInstantEdit={true}
                          onaddfile={(_, file) => {
                            if (_) return console.error(_);
                            replaceAssetImage([
                              {
                                url: URL.createObjectURL(file.file),
                                name: file.filename,
                                size: file.fileSize,
                                nativeFile: file.file as any,
                              },
                            ]);
                          }}
                          allowMultiple={false}
                          name="files" /* sets the file input name, it's filepond by default */
                          labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
                        />
                      ),
                      []
                    )}
                  </div>

                  <div className="flex flex-col items-start">
                    <Input
                      type="text"
                      className="mb-4"
          startContent={<h4 className="text-sm font-bold">NGN</h4>}
                      label="Min Price"
                      {...register("price_min", { required: true })}
                      errorMessage={errors.price_min?.message}
                      isInvalid={!isNil(errors.price_min?.message)}
                      color={errors.price_min?.message ? "danger" : undefined}
                    />

                    <Input
                      type="text"
                      label="Max Price"
          startContent={<h4 className="text-sm font-bold">NGN</h4>}
                      {...register("price_max", { required: true })}
                      errorMessage={errors.price_max?.message}
                      isInvalid={!isNil(errors.price_max?.message)}
                      color={errors.price_max?.message ? "danger" : undefined}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4 col-span-2">
                    <Input
                      type="symbol"
                      disabled={!isNil(props.asset?.id)}
                      label="Symbol"
                      placeholder="TRX"
                      {...register("symbol", { required: true })}
                      errorMessage={errors.symbol?.message}
                      isInvalid={!isNil(errors.symbol?.message)}
                      color={errors.symbol?.message ? "danger" : undefined}
                    />

                    <Select
                      label="Status"
                      placeholder="Select Status"
                      selectionMode="single"
                      {...register("status", { required: true })}
                      errorMessage={errors.status?.message}
                      isInvalid={!isNil(errors.status?.message)}
                      className="grow"
                    >
                      <SelectItem key="draft">Unpublished</SelectItem>
                      <SelectItem key="published">Published</SelectItem>
                    </Select>
                    
                    <Select
                      label="Level"
                      placeholder="Select Level"
                      selectionMode="single"
                      {...register("level", { required: true })}
                      errorMessage={errors.level?.message}
                      isInvalid={!isNil(errors.level?.message)}
                      className="col-span-2"
                    >
                      <SelectItem key="standard">Standard</SelectItem>
                      <SelectItem key="pro">Pro</SelectItem>
                      <SelectItem key="vip">VIP</SelectItem>
                      <SelectItem key="vvip">VVIP</SelectItem>
                    </Select>
                  </div>
                </div>
            </ModalBody>
            <ModalFooter>
              <Button color="warning" type="submit">
                Submit
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
        </form>
      </Modal>
    </>
  );
};
