import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  User,
} from "@nextui-org/react";
import { toast } from "react-toastify";
import { Deposit } from "../../../../lookups/deposits";
import { FormImage, LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../../../utils";
import { BASE_URL } from "../../../../utils/constants";
import { MoreHeaderComponent } from "./components/header-component";
import { Withdrawal } from "../../../../lookups/withdrawals";
import FeatherIcon from "feather-icons-react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { useContext, useState } from "react";
import { useBankList } from "../../../../lookups/banks";
import { first } from "lodash";
import { LoadingContext } from "../../../..";

export const WithdrawalDetailDialogFragment = (props: {
  withdrawal: Withdrawal;
  closeModal: (withdrawal: Withdrawal) => void;
}) => {
  const [isBanksDropdownOpen, setIsBanksDropdownOpen] = useState(false);
  const {
    items: banks,
    hasMore: hasMoreBanks,
    isLoading,
    onLoadMore: onLoadMoreBanks,
  } = useBankList({ fetchDelay: 0 });
  const { setLoading } = useContext(LoadingContext);

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreBanks,
    isEnabled: isBanksDropdownOpen,
    shouldUseLoader: false, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreBanks,
  });

  const changeWithdrawalStatus = (approved: boolean) => {
    setLoading!(true);
    fetch(
      approved
        ? `${BASE_URL}admin/withdrawals/approve/${props.withdrawal.id}`
        : `${BASE_URL}admin/withdrawals/reject/${props.withdrawal.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        },
      }
    )
      .then(async (response) => {
        if (response.status >= 400 && response.status < 600) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }
        const { withdrawal }: { withdrawal: Withdrawal } =
          await response.json();
        toast.success(approved ? "Withdrawal Approved" : "Withdrawal Rejected");
        props.closeModal(withdrawal);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      }).finally(() => {
        
        setLoading!(false);
          });;
  };

  return (
    <>
      <Modal
        size={"sm"}
        isOpen={true}
        onClose={() => props.closeModal(props.withdrawal)}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Withdrawal Details
              </ModalHeader>
              <ModalBody>
                <User
                  className="mb-4"
                  name={`${props.withdrawal.user.last_name ?? ""} ${
                    props.withdrawal.user.first_name ?? ""
                  }`.trim()}
                  description={(props.withdrawal.user as any).level}
                  avatarProps={{
                    src: first(
                      (props.withdrawal.user.metadata?.avatar as
                        | FormImage[]
                        | undefined) ?? []
                    )?.url,
                  }}
                />

                <Input
                  type="text"
                  className="mb-4"
                  label="Amount"
                  disabled
                  value={props.withdrawal.amount.toString()}
                  startContent={<h4 className="text-md font-bold">NGN</h4>}
                />
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Acccount Number"
                    disabled
                    value={props.withdrawal.user.metadata.account_number as any}
                    endContent={
                      <Button
                        isIconOnly
                        size="sm"
                        color="warning"
                        variant="faded"
                        aria-label="Copy account number"
                        onClick={async () => {
                          await navigator.clipboard.writeText(
                            props.withdrawal.user.metadata.account_number as any
                          );
                          toast.success(`Copied`);
                        }}
                      >
                        <FeatherIcon icon="copy" size={"16px"} />
                      </Button>
                    }
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Acccount Name"
                    disabled
                    value={props.withdrawal.user.metadata.account_name as any}
                  />
                </div>
                <div className="mb-4">
                  <Select
                    isLoading={isLoading}
                    items={banks}
                    label="Pick a Bank"
                    placeholder="Select a Bank"
                    defaultSelectedKeys={[
                      props.withdrawal.user.metadata?.bank_code as any,
                    ]}
                    scrollRef={scrollerRef}
                    selectionMode="single"
                    onOpenChange={setIsBanksDropdownOpen}
                  >
                    {(item) => (
                      <SelectItem key={item.code} className="capitalize">
                        {item.name}
                      </SelectItem>
                    )}
                  </Select>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  fullWidth={true}
                  color="success"
                  onClick={() => changeWithdrawalStatus(true)}
                >
                  Complete
                </Button>
                <Button
                  fullWidth={true}
                  color="warning"
                  onClick={() => changeWithdrawalStatus(false)}
                >
                  Reject
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
