import { useMemo, useState } from "react";
import { Asset } from "../../../../lookups/assets";
import { AssetDetailComponent } from "./components/asset-detail-component";
import { Chart } from "./components/chart";
import { AssetsHeaderComponent } from "./components/header-component";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_LAST_VISIBLE_TRADE_PAIR,
} from "../../../../utils";
import { BASE_URL } from "../../../../utils/constants";
import { first } from "lodash";
import { useLoaderData } from "react-router-dom";

export const AssetDetaulFragmentLoader = async ({ params, request }: any) => {
  const [, searchParams] = request.url.split("?");
  let symbol = new URLSearchParams(searchParams).get("symbol");

  if (!symbol) {
    symbol = localStorage.getItem(LOCAL_STORAGE_LAST_VISIBLE_TRADE_PAIR);
  }
  

  if(symbol){
  const req = await fetch(`${BASE_URL}admin/assets/${symbol}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_ACCESS_TOKEN_KEY
      )}`,
    },
  });


  if (!req.ok) {
    const { message, errors }: { message: string; errors: string[] } =
      await req.json();
    throw new Error(message ?? first([errors]));
  }


  const { asset }: { asset: Asset } = await req.json();

  return asset ;}
  return null;
};

export const AssetsFragment = () => {
  const selectedAsset: Asset = useLoaderData() as Asset;

  if (selectedAsset?.symbol)
    localStorage.setItem(
      LOCAL_STORAGE_LAST_VISIBLE_TRADE_PAIR,
      selectedAsset.symbol
    );

  const [asset, setAsset] = useState<Asset>(selectedAsset ?? undefined);

  return (
    <div className="flex flex-col ">
      <AssetsHeaderComponent
        selectedKeys={selectedAsset?.symbol ? [selectedAsset.symbol] : []}
        onAssetChange={(asset) => {
          setAsset(asset);
          localStorage.setItem(
            LOCAL_STORAGE_LAST_VISIBLE_TRADE_PAIR,
            asset.symbol
          );
        }}
      />

      {useMemo(
        () =>
          asset && (
            <>
              <Chart asset={asset} key={`chart_${asset.symbol}`} />

              <div className="p-4">
                <AssetDetailComponent
                  asset={asset}
                  key={`detail_${asset.symbol}`}
                />
              </div>
            </>
          ),
        [asset]
      )}
    </div>
  );
};
