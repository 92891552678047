import { Button, Chip, Input, User } from "@nextui-org/react";
import { toast } from "react-toastify";
import { Deposit, DepositStatus } from "../../../../lookups/deposits";
import { FormImage, LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../../../utils";
import { BASE_URL } from "../../../../utils/constants";
import { MoreHeaderComponent } from "../more/components/header-component";
import { first } from "lodash";
import { useContext } from "react";
import { LoadingContext } from "../../../..";

export const DepositDetailFragment = (props: {
  deposit: Deposit;
  closeModal: (deposit: Deposit) => void;
}) => {
  
  const statusColorMap = {
    pending: "warning",
    approved: "success",
    failed: "danger",
  };
  const { setLoading } = useContext(LoadingContext);


  const changeWithdrawalStatus = (approved: boolean) => {
    setLoading!(true);
    fetch(
      approved
        ? `${BASE_URL}admin/deposits/approve/${props.deposit.id}`
        : `${BASE_URL}admin/deposits/reject/${props.deposit.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        },
      }
    )
      .then(async (response) => {
        if (response.status >= 400 && response.status < 600) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }
        const { deposit }: { deposit: Deposit } = await response.json();
        toast.success(approved ? "Deposit Approved" : "Deposit Rejected");
        props.closeModal(deposit);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      }).finally(() => {
        
    setLoading!(false);
      });
  };

  return (
    <>
      <div className="flex flex-col items-start">
        {/* <div style={{ marginTop: "56px" }}></div> */}

        <div className="mt-2 mb-2">
          <MoreHeaderComponent
            title="Deposit Detail"
            onClose={props.closeModal}
          />
        </div>
        <div className="p-8 w-full">
          <div className="flex flex-rows justify-between">

          <small className="text-default-500">Receipt Information</small>
          
          {
                    <Chip
                      className="capitalize"
                      color={statusColorMap[props.deposit.status] as any}
                      size="sm"
                      variant="flat"
                    >
                      {props.deposit.status}
                    </Chip>
                  }
          </div>

          <div
            className="w-full bg-slate-200 mt-2 mb-4"
            style={{ height: 1 }}
          ></div>

          <User
            className="mb-4"
            name={`${props.deposit.user.last_name ?? ""} ${
              props.deposit.user.first_name ?? ""
            }`.trim()}
            description={(props.deposit.user as any).level}
            avatarProps={{
              src: first(
                (props.deposit.user.metadata?.avatar as any[]) ?? [] as any[]
              )?.url,
            }}
          />

          <img
            src={(props.deposit.proof as FormImage)?.url as any}
            style={{ width: "auto" }}
          />

          <div className="my-4 grid grid-cols-2 gap-4">
            <Input
              type="text"
              label="Deposited Amount"
              disabled
              value={props.deposit.amount.toString()}
              startContent={
                <h4 className="text-md font-bold">
                  {props.deposit.currency.toUpperCase()}
                </h4>
              }
            />
            <Input
              type="text"
              disabled
              readOnly
              label="Narration"
              value={props.deposit.narration?.toString()}
            />
          </div>

          {props.deposit.status == DepositStatus.PENDING && (
            <div className="grid grid-cols-2 gap-4">
              <Button
                fullWidth={true}
                color="success"
                onClick={() => changeWithdrawalStatus(true)}
              >
                Approve
              </Button>
              <Button
                fullWidth={true}
                color="warning"
                onClick={() => changeWithdrawalStatus(false)}
              >
                Reject
              </Button>
            </div>
          )}
          {props.deposit.status == DepositStatus.FAILED && (
            <div className="grid grid-cols-2 gap-4">
              <Button
                fullWidth={true}
                color="success"
                onClick={() => changeWithdrawalStatus(true)}
              >
                Approve
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
