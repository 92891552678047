import Medusa from "@medusajs/medusa-js";
import { createContext } from "react";
import ReactDOM from "react-dom/client";
import { RouteGuard } from "react-guardian";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import { App } from "./App";
import { loggedInOnlyPolicy } from "./guards";
import "./index.css";
import LoginPage from "./pages/authentication/login";
import HomePage from "./pages/home";
import { MoreFragment } from "./pages/home/fragments/more/more-fragment";
import { AssetDetaulFragmentLoader, AssetsFragment } from "./pages/home/fragments/assets/assets-fragment";
import Root from "./pages/root";
import reportWebVitals from "./reportWebVitals";
import { BASE_URL } from "./utils/constants";
import { CustomerListFragment } from "./pages/home/fragments/customers/customer-list-fragment";
import { DepositWithdrawalsFragment } from "./pages/home/fragments/more/deposits-withdrawals-fragment";
import { NotificationsListFragment } from "./pages/home/fragments/notifications/notifications-fragmnt";

export const medusa = new Medusa({
  baseUrl: BASE_URL,
  maxRetries: 2,
});
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} id="root">
      <Route
        path="home"
        element={
          <RouteGuard policies={[loggedInOnlyPolicy]}>
            <HomePage />
          </RouteGuard>
        }
        children={[
          <Route index path="assets" element={<AssetsFragment />} 
          loader={AssetDetaulFragmentLoader}/>,
          <Route path="customers" element={<CustomerListFragment />} />,
          <Route path="more" element={<MoreFragment />} />,
          <Route
            path="notifications"
            element={<NotificationsListFragment />}
          />,
          <Route path="deposit-and-withdrawals" element={<DepositWithdrawalsFragment />} />,
          <Route
            path="/home/"
            element={<Navigate to="/home/assets" replace={true} />}
          />,
          <Route path="/home/*" element={<Navigate to="/home/assets" />} />,
        ]}
      />
      <Route path="login" element={<LoginPage />} />
      <Route path="/" element={<Navigate to="/login" replace={true} />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const LoadingContext = createContext<{
  loading: boolean;
  setLoading?: (loading: boolean) => void;
}>({ loading: false });

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
