// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import FilePondPluginImageEditor from "@pqina/filepond-plugin-image-editor";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
// import FilePondPluginImageEditor from "../../scripts/filepond-plugin-image-editor"
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  getEditorDefaults,
  openEditor,
  plugin_crop,
  processImage,
  setPlugins,
} from "../../../../../scripts/pintura";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import { isNil } from "lodash";
import { toast } from "react-toastify";
import { LoadingContext } from "../../../../..";
import { Asset } from "../../../../../lookups/assets";
import {
  FormImage,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
} from "../../../../../utils";
import { BASE_URL } from "../../../../../utils/constants";
import { prepareImages } from "../../../../../utils/images";

// Register the plugins
registerPlugin(
  /* FilePondPluginImageExifOrientation, FilePondPluginImagePreview, */
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster,
  FilePondPluginFileValidateSize
);

setPlugins(plugin_crop);

type BurstNotification = {
  title: string;
  content: number;
  avatar?: Record<string, any>;
};

export type NewNotificationFormType = {
  title: string;
  content: number;
  avatar?: FormImage[];
};

export const NewNotificationComponentDialog = (props: {
  isOpen: boolean;
  closeModal: (notification?: BurstNotification) => void;
}) => {
  const schema = yup
    .object({
      title: yup.string().required(),
      content: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<NewNotificationFormType>({
    defaultValues: {
      avatar: [],
    } as any,
    resolver: yupResolver(schema as any),
  });
  const { replace: replaceAssetImage, fields: avatarFields } = useFieldArray({
    control: control,
    name: "avatar",
  } as any);

  const imageEditorOptions = {
    // Maps legacy data objects to new imageState objects (optional)
    // legacyDataToImageState: legacyDataToImageState,

    // Used to create the editor (required)
    createEditor: openEditor,

    // Used for reading the image data. See JavaScript installation for details on the `imageReader` property (required)
    imageReader: [createDefaultImageReader],

    // Required when generating a preview thumbnail and/or output image
    imageWriter: [createDefaultImageWriter],

    // Used to create poster and output images, runs an invisible "headless" editor instance
    imageProcessor: processImage,

    // Pintura Image Editor options
    editorOptions: {
      // Pass the editor default configuration options
      ...getEditorDefaults(),

      // This will set a square crop aspect ratio
      // placeholder="1200 x 1600 (3:4) recommended, up to 10MB each"
      imageCropAspectRatio: 1,
    },
  };

  const { setLoading } = useContext(LoadingContext);
  const { client: medusaClient } = useMedusa();

  const createNotification = async (data: NewNotificationFormType) => {
    console.error(data);
    setLoading!(true);

    let preppedImages: FormImage[] = [];

    try {
      preppedImages = await prepareImages(medusaClient, data.avatar ?? []);
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the thumbnail.";

      toast.error((error as any) ?? errorMessage);
      return;
    } finally {
      setLoading!(false);
    }

    // create the deposit and dispose
    fetch(`${BASE_URL}admin/domain/notifications`, {
      method: "POST",
      body: JSON.stringify({
        title: data.title,
        content: data.content,
        image: preppedImages[0],
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }

        const { notification }: { notification: BurstNotification } =
          await response.json();
        // close dialog

        toast.success("Notification added successfully");

        reset();
        props.closeModal(notification);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <>
      <Modal
        size={"sm"}
        isOpen={props.isOpen}
        onClose={() => {
          props.closeModal();
          reset();
        }}
      >
        <form onSubmit={handleSubmit(createNotification)}>
          <ModalContent>
            <>
              <ModalHeader className="flex flex-col gap-1">
                Create Notification
              </ModalHeader>
              <ModalBody>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="col-span-2">
                    {useMemo(
                      () => (
                        <FilePond
                          // filePosterMaxHeight={200}
                          maxFileSize={"3072KB"}
                          styleLoadIndicatorPosition="center bottom"
                          styleProgressIndicatorPosition="right bottom"
                          styleButtonRemoveItemPosition="left bottom"
                          maxFiles={1}
                          styleButtonProcessItemPosition="right bottom"
                          acceptedFileTypes={[
                            "image/gif",
                            "image/jpeg",
                            "image/png",
                            "image/webp",
                          ]}
                          server={{
                            load: (
                              source,
                              load,
                              error,
                              progress,
                              abort,
                              headers
                            ) => {
                              var myRequest = new Request(source);
                              fetch(myRequest)
                                .then(function (response) {
                                  response.blob().then(function (myBlob) {
                                    load(myBlob);
                                  });
                                })
                                .catch(error);
                            },
                          }}
                          files={avatarFields.map((field) => ({
                            options: {
                              type: "local",
                            },
                            source: (field as any).url,
                          }))}
                          // imageEditEditor={createEditor}
                          imageEditor={imageEditorOptions}
                          // imageEditorInstantEdit={true}
                          onaddfile={(_, file) => {
                            if (_) return console.error(_);
                            replaceAssetImage([
                              {
                                url: URL.createObjectURL(file.file),
                                name: file.filename,
                                size: file.fileSize,
                                nativeFile: file.file as any,
                              },
                            ]);
                          }}
                          allowMultiple={false}
                          name="files" /* sets the file input name, it's filepond by default */
                          labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
                        />
                      ),
                      []
                    )}
                  </div>

                  <div className="flex  col-span-2 flex-col items-start">
                    <Input
                      type="text"
                      className="mb-4"
                      label="Title"
                      {...register("title", { required: true })}
                      errorMessage={errors.title?.message}
                      isInvalid={!isNil(errors.title?.message)}
                      color={errors.title?.message ? "danger" : undefined}
                    />

                    <Textarea
                      label="Content"
                      {...register("content", { required: true })}
                      errorMessage={errors.content?.message}
                      isInvalid={!isNil(errors.content?.message)}
                      color={errors.content?.message ? "danger" : undefined}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="warning" type="submit">
                  Submit
                </Button>
              </ModalFooter>
            </>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};
