import { Link, Listbox, ListboxItem, Switch, User } from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import { ReactNode, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
  LOCAL_STORAGE_PREFER_LINE_CHART_KEY,
} from "../../../../../utils";

export const MoreTileComponenets = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<{
    logoutSuccessful: boolean;
    demoMode: boolean;
    preferLineChart: boolean;
  }>({
    logoutSuccessful: false,
    demoMode: localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY) ? true : false,
    preferLineChart: localStorage.getItem(LOCAL_STORAGE_PREFER_LINE_CHART_KEY)
      ? true
      : false,
  });

  const actions: {
    label: string;
    link?: string;
    description?: string;
    action?: (setState: (_: any) => any) => void;
    isDestructive?: boolean;
    leading?: ReactNode;
    ending?: ReactNode;
  }[] = [
    {
      label: "Deposits & Withdrawals",
      description: "Approve, Reject",
      link: "/home/deposit-and-withdrawals",
      leading: <FeatherIcon icon="percent" />,
    },
    {
      label: "Line Chart",
      description: "Use a line chart instead of a Candle chart",
      leading: <FeatherIcon icon="activity" />,
      ending: (
        <Switch
          isSelected={state.preferLineChart}
          className="pointer-events-none"
        />
      ),
      action: (setState: (_: any) => any) => {
        setState({ ...state, preferLineChart: !state.preferLineChart });

        !state.preferLineChart
          ? localStorage.setItem(LOCAL_STORAGE_PREFER_LINE_CHART_KEY, "1")
          : localStorage.removeItem(LOCAL_STORAGE_PREFER_LINE_CHART_KEY);
      },
    },
    {
      label: "Log out",
      isDestructive: true,
      leading: <FeatherIcon icon="log-out" />,
      action: (setState: (_: any) => any) => {
        localStorage.clear();
        setState({ ...state, logoutSuccessful: true });
      },
    },
  ];

  return (
    <>
      <Listbox
        items={actions}
        variant="flat"
        className="px-4 "
        aria-label="Listbox menu with descriptions"
      >
        {(a) => (
          <ListboxItem
            key={a.label}
            description={a.description}
            startContent={a.leading}
            endContent={a.ending}
            className={`${a.isDestructive ? "text-danger" : ""}`}
            color={`${a.isDestructive ? "danger" : "default"}`}
            onClick={() => (a.link ? navigate(a.link!) : a.action!(setState))}
          >
            {a.label}
          </ListboxItem>
        )}
      </Listbox>

      {state.logoutSuccessful && <Navigate to="/login" />}
    </>
  );
};
