import React from "react";
import { BASE_URL } from "../utils/constants";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
} from "../utils";
import { Asset } from "./customer-assets";
import { debounce, first } from "lodash";
import { DateValue, RangeValue } from "@nextui-org/react";
export type Trade = {
  id: number;
  symbol: string;
  sl: number;
  tp: number;
  amount: number;
  executed: boolean;
  type: "buy" | "sell";
  category: "market" | "limit";
  userId: string;
  created_at: string;
  limit: number;
  asset: Asset;
};

export type UseCustomerTradeListProps = {
  /** Delay to wait before fetching more items */
  symbol?: string;
  userId?: string;
};

export function useCustomerTradeList({symbol, userId}: UseCustomerTradeListProps) {
  const [items, setItems] = React.useState<Trade[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const limit = 10; // Number of items per page, adjust as necessary

  const loadCustomerTrades = async (currentOffset: number, range?:RangeValue<DateValue>, forceFetch = false) => {
    if(!forceFetch && (!hasMore || isLoading)) return;
    const controller = new AbortController();
    const { signal } = controller;

    try {
      setIsLoading(true);

      const req = await fetch(
        `${BASE_URL}admin/customer/trades?offset=${currentOffset}&limit=${limit}${symbol?`&symbol=${symbol}`:''}${userId?`&userId=${userId}`:''}${range ?  `&start_date=${range.start}&end_date=${range.end}`:''}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LOCAL_STORAGE_ACCESS_TOKEN_KEY
            )}`,
            demo: localStorage.getItem(LOCAL_STORAGE_DEMO_MODE_KEY)
              ? "yes"
              : "no",
          },
        }
      );

      if (!req.ok) {
        const { message, errors }: { message: string, errors: string[]  } = await req.json();
        throw new Error(message ?? first([errors]));
      }  if(req.status == 401) {
        // logout
        localStorage.clear();
        window.location.pathname = '/';
      }

      const {
        trades,
        offset: _offset,
        limit: _limit,
        count: _count,
      }: {
        trades: Trade[];
        offset: number;
        limit: number;
        count: number;
      } = await req.json();

      setItems((prevItems) => [...prevItems, ...trades]);
      setHasMore(
        !(_offset == 0 ? items.length >= _count! : _offset! + 1 >= _count!)
      );
    
    } catch (error: any) {
      if((error?.response as any)?.status == 401) {
        
        localStorage.clear();
        window.location.pathname = '/';
      }else
      if ((error as any).name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("There was an error with the fetch operation:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadCustomerTrades(offset);
  }, []);

  const onLoadMore = debounce(() => {
    if((!hasMore || isLoading)) return;
    
    const newOffset = offset + limit;
    setOffset(newOffset);
    loadCustomerTrades(newOffset);
  }, 300);

  const refresh = (range?: RangeValue<DateValue>) => {
    onLoadMore.cancel();
    setOffset(0);
    setItems([]);
    loadCustomerTrades(0, range, true);
  }

  return {
    items,
    hasMore,
    isLoading,
    onLoadMore,
    refresh
  };
}
