import { Customer } from "@medusajs/medusa";
import {
  Avatar,
  Input,
  Listbox,
  ListboxItem,
  Spinner,
} from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import FeatherIcon from "feather-icons-react";
import { first } from "lodash";
import { useState } from "react";
import { ListboxWrapper } from "../../../../components/list-box-component";
import { useCustomerList } from "../../../../lookups/customers";
import { FormImage } from "../../../../utils";
import { MoreHeaderComponent } from "../more/components/header-component";
import { CustomerDetailFragment } from "./customer-detail-fragment";

export const CustomerListFragment = () => {
  const {
    items: customers,
    hasMore: hasMoreCustomers,
    isLoading,
    onLoadMore: onLoadMoreCustomers,
    setQ,
    count,
  } = useCustomerList({ fetchDelay: 0 });

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreCustomers,
    isEnabled: true,
    shouldUseLoader: true, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreCustomers,
  });

  const [activeCustomer, setActiveCustomer] = useState<Customer>();

  return (
    <div className="flex flex-col items-start">
      {/* <div style={{ marginTop: "56px" }}></div> */}

      <div className="mt-2 mb-2 ">
        <MoreHeaderComponent title="Customers" />
      </div>

      <div className="w-full px-8 mb-4"></div>

      <div
        className={`grow px-8 w-full ${activeCustomer && "hidden"}`}
        style={{ paddingTop: "56px" }}
      >
        <ListboxWrapper>
          <Listbox
            ref={scrollerRef}
            topContent={
              <Input
                label="Search Customers"
                isClearable
                radius="md"
                className="w-full"
                onChange={(ev) => {
                  setQ(ev.target.value);
                }}
                placeholder="Type to search..."
                startContent={
                  isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <FeatherIcon icon="search" />
                  )
                }
              />
            }
            bottomContent={
              <div ref={_ as any} className="flex flex-col items-center">
                <div
                  className="w-full bg-slate-200 mt-2"
                  style={{ height: 1 }}
                ></div>

{isLoading ? <Spinner  size="sm" /> : <h4 className="text-sm mt-2">
                  SHOWING {customers.length} OF {count}
                </h4>}
              </div>
            }
            classNames={{
              base: "",
              list: "page-listbox overflow-scroll",
            }}
            // style={{maxHeight: 'calc(100vh - 200px)'}}
            defaultSelectedKeys={["1"]}
            items={customers}
            label="Customers"
            selectionMode="none"
            variant="flat"
          >
            {(item) => (
              <ListboxItem
                key={item.id}
                onClick={() => setActiveCustomer(item)}
                textValue={`${item.last_name} ${item.first_name}`}
              >
                <div className="flex gap-2 items-center">
                  <Avatar
                    alt={"avatar"}
                    className="flex-shrink-0"
                    size="sm"
                    src={
                      first(
                        (item.metadata?.avatar as FormImage[] | undefined) ?? []
                      )?.url
                    }
                  />
                  <div className="flex flex-col">
                    <span className="text-small">
                      {item.last_name ?? ""}&nbsp; {item.first_name ?? ""}
                    </span>
                    <span className="text-tiny text-default-400">
                      {item.email}
                    </span>
                  </div>
                </div>
              </ListboxItem>
            )}
          </Listbox>
        </ListboxWrapper>
      </div>

      {activeCustomer && (
        <div className="w-full">
          {" "}
          <CustomerDetailFragment
            key={`viewe_customer_${activeCustomer.id}`}
            customer={activeCustomer}
            onClose={(_) => {
              if (_ && _.id) setActiveCustomer(_);
              else setActiveCustomer(undefined);
            }}
          ></CustomerDetailFragment>
        </div>
      )}
    </div>
  );
};
