import { Button, Select, SelectItem, User } from "@nextui-org/react";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { NewNotificationComponentDialog } from "../../more/components/new-notification-component";
import { Chance } from "chance";


export const MoreHeaderComponent = (props: {
  title?: string;

  onClose?: (_: any) => void;
  refresh?: () => void;
}) => {
  const [isNewNotificationDialogOpen, setIsNewNotificationDialogOpen] = useState(false);

  return (
    <>
    
      <div className="z-30 flex flex-rows justify-between  absolute top-0 border-b-2 border-slate-300 flex w-full bg-white rounded-none items-center">
        {props.onClose ? (
          <Button
            isIconOnly
            color="warning"
            aria-label="Back"
            radius="none"
            style={{ width: "58px", height: "58px" }}
            onClick={props.onClose}
          >
            <FeatherIcon icon="arrow-left" />
          </Button>
        ) : (
          <div
            className="flex-none bg-white rounded-md"
            style={{ width: "58px" }}
          >
            <img src="/autrid_borderless.png" />
          </div>
        )}

        {props.title && <h1 className="text-lg font-bold">{props.title}</h1>}
        
        <Button
          isIconOnly
          color="warning"
          aria-label="New Token"
          radius="none"
          style={{ width: "75px", height: "58px" }}
          onClick={() => setIsNewNotificationDialogOpen(true)}
        >
          <FeatherIcon icon="plus" />
        </Button>
      </div>

      
      <NewNotificationComponentDialog
      // key={`new_notification_${(new Chance()).string({length: 3, symbols: false})}`}
        isOpen={isNewNotificationDialogOpen}
        closeModal={(_) => {
          setIsNewNotificationDialogOpen(false);
          if(_)
            props.refresh &&props.refresh();
        }}
      ></NewNotificationComponentDialog>
    </>
  );
};
