import { Avatar, DateRangePicker, Link, Listbox, ListboxItem, Spinner, User } from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { first } from "lodash";
import { ListboxWrapper } from "../../../../../components/list-box-component";
import { useCustomerAssetList } from "../../../../../lookups/customer-assets";
import {
  FormImage,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_DEMO_MODE_KEY,
} from "../../../../../utils";
import { useContext } from "react";
import { toast } from "react-toastify";
import { LoadingContext } from "../../../../..";
import { BASE_URL } from "../../../../../utils/constants";
import { prepareImages } from "../../../../../utils/images";
import { DateTime } from "luxon";
import { useCustomerTradeList } from "../../../../../lookups/customer-trades";

export const CustomerOpenTradesListComponent = (props: { symbol?: string, userId?: string }) => {
  const {
    items: trades,
    hasMore: hasMoreTrades,
    isLoading,
    onLoadMore: onLoadMoreTrades,
    refresh,
  } = useCustomerTradeList({ symbol: props.symbol, userId: props.userId });

  const [_, scrollerRef] = useInfiniteScroll({
    hasMore: hasMoreTrades,
    isEnabled: true,
    shouldUseLoader: true, // We don't want to show the loader at the bottom of the list
    onLoadMore: onLoadMoreTrades,
  });

  return (
    <div className="flex flex-col items-start">
      <div className={`grow px-2 w-full`}>
        <ListboxWrapper >
          <Listbox
            classNames={{
              base: "",
              list: "page-listbox-2 overflow-scroll",
            }}
            topContent={
              <DateRangePicker
                startContent={isLoading ? <Spinner size="sm" /> : undefined}
                label="Range"
                onChange={(ev) => refresh(ev)}
                className=""
              />
            }
            ref={scrollerRef}
            bottomContent={<div ref={_ as any}> {isLoading && <Spinner  size="sm" />}</div>}
            // style={{maxHeight: 'calc(100vh - 200px)'}}
            items={trades}
            label="Open Trades"
            selectionMode="none"
            variant="flat"
          >
            {(item) => (
              <ListboxItem key={item.id} textValue={item.symbol}>
                <div className="flex flex-rows items-center">
                  {item.asset.metadata.avatar &&
                    item.asset.metadata.avatar[0] && (
                      <Avatar
                        size="md"
                        src={item.asset.metadata.avatar[0].url}
                      />
                    )}
                  <div className="ml-2 flex flex-col items-start">
                    <p className="text-md font-semibold">
                      {item.asset.symbol}
                      <span
                        className={`text-sm ${
                          item.type == "sell"
                            ? "text-red-500"
                            : "text-green-400"
                        }`}
                      >
                        {item.type}
                      </span>
                    </p>

                  </div>

                  <div className="grow"></div>

                  {/* put the amount in circulation here */}

                  <div className="flex flex-col items-end">
                    <p className="text-md font-semibold">
                      {item.type == "sell"
                        ? `${item.amount} ${item.asset.symbol}`
                        : new Intl.NumberFormat("en-US", {
                            currency: "NGN",
                            style: "currency",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(item.amount)}{" "}
                    </p>
                  </div>
                </div>
                    {item.category == 'limit' && <p className="text-slate-600 text-sm">
                      <span>
                        Limit:{" "}
                        {item.limit
                          ? new Intl.NumberFormat("en-US", {
                              currency: "NGN",
                              style: "currency",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(item.limit)
                          : "N/A"}
                        &nbsp;&nbsp;
                      </span>

                      {item.type == "sell" && (
                        <span>
                          TP:{" "}
                          {item.tp
                            ? new Intl.NumberFormat("en-US", {
                                currency: "NGN",
                                style: "currency",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item.tp)
                            : "N/A"}
                          &nbsp;&nbsp;
                        </span>
                      )}

                      {item.type == "sell" && (
                        <span>
                          SL:{" "}
                          {item.sl
                            ? new Intl.NumberFormat("en-US", {
                                currency: "NGN",
                                style: "currency",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item.sl)
                            : "N/A"}
                          &nbsp;&nbsp;
                        </span>
                      )}
                    </p>}

                <div className="flex flex-rows items-end self-end">
                  <h5 className="text-small tracking-tight text-default-400">
                    Placed{" "}
                    {DateTime.fromISO(item.created_at).toLocaleString(
                      DateTime.DATETIME_SHORT
                    )}
                  </h5>
                </div>
                <div
                  className="w-full bg-slate-200 mt-2"
                  style={{ height: 1 }}
                ></div>
              </ListboxItem>
            )}
          </Listbox>
        </ListboxWrapper>
      </div>
    </div>
  );
};
